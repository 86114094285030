const baseUrl = "https://graph.instagram.com/";

interface IUserIdModel {
    id: string;
}

interface IPostIdItemModel {
    id: string;
}

interface IPostIdModel {
    data: IPostIdItemModel[];
}

export interface IInstagramPostModel {
    id: string;
    media_url: string;
    media_type: string;
    permalink: string;
    thumbnail_url?: string;
    timestamp: string;
}


export const getPosts = async (accessToken: string): Promise<IInstagramPostModel[]> => {

    const { id } = await fetchUserId(accessToken);
    if (!id) {
        return [];
    }

    let { data } = await fetchUserPostIds(id, accessToken);
    if (!data) {
        return [];
    }

    data = data.slice(0, 5);

    const promises = data.map(x => fetchUserPost(x.id, accessToken));

    const posts = await Promise.all(promises);

    return posts;
}

const fetchUserId = async (accessToken: string): Promise<IUserIdModel> => {
    const url = `${baseUrl}/me?access_token=${accessToken}`;
    try {
        const response = await fetch(url);
        const data = await response.json();

        return data;
    }
    catch {
        return {
            id: ""
        };
    }
}

const fetchUserPostIds = async (userId: string, accessToken: string): Promise<IPostIdModel> => {

    const url = `${baseUrl}/${userId}/media?access_token=${accessToken}`;

    try {
        const response = await fetch(url);
        const data = await response.json();
        return data;
    }
    catch {
        return {
            data: []
        };
    }
}

const fetchUserPost = async (postId: string, accessToken: string): Promise<IInstagramPostModel> => {
    const url = `${baseUrl}/${postId}?access_token=${accessToken}&fields=media_type,media_url,permalink,thumbnail_url,timestamp`;

    const response = await fetch(url);
    const data = await response.json();

    return data;
}