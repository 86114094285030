import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { HomePageModel } from "../models/pages";
import get from "lodash/get"
import Seo from "../components/Seo"
import { SeoDataModel } from "../models/common"
import { getPrettyURL } from "../utils"
import InstagramFeed from "../components/InstagramFeed/InstagramFeed";
import PageLayout from "../components/PageLayout/PageLayout"
import { InstagramFeedModel } from "../models/components";
import { fetchLivePreviewData, contentstackLivePreviewWrapper } from "../live-preview"

const defaultProps = {}

const getInstagramFeedModel = (page: HomePageModel): InstagramFeedModel => {
    
    const model: InstagramFeedModel = {
        accessToken: page.instagram_access_token,
        profileImageUrl: page.instagram_profile_image?.url ?? "",
        profileName: page.instagram_profile_name,
        profileUrl: page.instagram_profile_url
    }

    return model;
}

export const HomePage: React.FC<HomePageModel> = (props) => {
    
    const _page = get(props, "data.page") as any as HomePageModel;    
    _page.pageContext = props.pageContext;

    const [page, setPage] = useState(_page);

    useEffect(() => {
        contentstackLivePreviewWrapper(fetchLivePreviewData(_page, setPage, props.pageContext));
    }, [])

    const instagramFeedModel = getInstagramFeedModel(page);

    // SEO Fields
    const seoData: SeoDataModel = {
        metaTitle: page.seo?.title,
        metaDescription:
            page.seo?.description,
        metaLink: page.seo?.canonical_link,
        metaKeywords: page.seo?.keywords,
        metaImage: page.seo?.share_image,
        slug: `/${props.pageContext.language}/${getPrettyURL(page.url)}`,
        locale: props.pageContext.locale,
        pageContext: props.pageContext,
    }

    const alteredContext = {
        ...props.pageContext,
        homePage: true
    };

    return (
        <div className="home-page">
            <Seo {...seoData} />
            <section>
                {page.body?.map((layout, index) => (
                    <PageLayout
                        key={`layout_${index}`}
                        {...layout}
                        hasSidebar={false}
                        fullWidth={true}
                        pageContext={alteredContext}
                    />
                ))}
                <InstagramFeed {...instagramFeedModel}  />

            </section>
        </div>
    );
}

HomePage.defaultProps = defaultProps;

export default HomePage;

export const query = graphql`
  query HomePageByID($pageId: String!) {
    page: contentstackHome(id: { eq: $pageId }) {
        __typename
        uid
        title
        instagram_profile_name
        instagram_profile_url
        instagram_profile_image {
            ...CmsImageFields
        }
        instagram_access_token
        seo {
            title
            description
            canonical_link
            keywords
            share_image {
                ...CmsImageFields
                gatsbyImageData(quality: 95)
            }
        }
        body {
            body {
                recipe_collection {
                    center_title_text
                    number_of_records
                    title
                    collection {
                    hero {
                        heading
                    }
                    recipes {
                        ...RecipeFields
                    }
                    }
                }
                html_text {
                    html
                }
            }
            sidebar {
                rich_text {
                    rich_text
                }
            }
            blocks {
                ...AllBlocks
            }
        }  
    }
}`